import revive_payload_client_KzxDhi1XDX from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ior_64c5dca30545affae3c712998f602d26/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gyRiG7iGz5 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ior_64c5dca30545affae3c712998f602d26/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nnqqRzhIgQ from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ior_64c5dca30545affae3c712998f602d26/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_8closNmbjF from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt-site-config@3.0.4_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sas_c2398bf358675d87959526ce96c92c3d/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import _00_device_aUuIKrR2pg from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/00.device.ts";
import check_outdated_build_client_teesXOJzgp from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ior_64c5dca30545affae3c712998f602d26/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_k5mbNhx8ZX from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.28.0_typescript@5.4.5_vue@3.4.25_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/apps/k1/.nuxt/components.plugin.mjs";
import prefetch_client_OJG4FunfXm from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ior_64c5dca30545affae3c712998f602d26/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_C1bowSuKi3 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/modules/meta-pixel/src/runtime/plugin.client.ts";
import plugin_z79dVQDFd5 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@4.28.0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import i18n_hujAepMKOT from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.28.0_vue@3.4.25_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import siteConfig_Zep0zRmCO0 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt-seo-utils@6.0.3_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@_88215b70f6e8ef02a10136adb75a4f07/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_HeXAwT4uNp from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt-seo-utils@6.0.3_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@_88215b70f6e8ef02a10136adb75a4f07/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import defaultsWaitI18n_J8rSNAGj0J from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt-seo-utils@6.0.3_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@_88215b70f6e8ef02a10136adb75a4f07/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import pwa_icons_TVzF1TY8bp from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.4_rollup@4.28.0_vite@5.4.11_@types+_36a1099366d7c48153b1f4d959fbc0fb/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_2KEKlcZTpL from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.4_rollup@4.28.0_vite@5.4.11_@types+_36a1099366d7c48153b1f4d959fbc0fb/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_oqqOpC6h04 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ior_64c5dca30545affae3c712998f602d26/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_sC5UVbOOy7 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.28.0_typescript@5.4._7bae59c188b348dca81237ed759b9db7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import varlet_e6cbQlfUS9 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/ui/plugins/varlet.ts";
import _00_chn_client_XVTQndAaeq from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/00.chn.client.ts";
import _00_error_3sMOwMwCju from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/00.error.ts";
import _00_i18n_Wc20tMlzRa from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/00.i18n.ts";
import _00_router_client_Rzbf5Pkz84 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/00.router.client.ts";
import _02_track_client_U0FmS0gvV8 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/02.track.client.ts";
import animate_6NGGNLtrdr from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/animate.ts";
import gtm_client_BwQMnIPzIp from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/gtm.client.ts";
import rem_client_SiWW72QDVo from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/rem.client.ts";
import storage_client_8KnqLy3umo from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/storage.client.ts";
import timer_N4XvhmDp4S from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/timer.ts";
import volce_client_Oe0Cz9whbZ from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/core/plugins/volce.client.ts";
import _00_fingerprint_client_FeTQqCRBfI from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/plugins/00.fingerprint.client.ts";
import _00_initialize_client_fjFfTWWSbo from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/plugins/00.initialize.client.ts";
import _02_dialog_client_eTTcJMjZVV from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/plugins/02.dialog.client.ts";
import _03_firebase_client_4CUfs1Y4Uo from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/plugins/03.firebase.client.ts";
import auth_client_8Tb6Ckgkt3 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/plugins/auth.client.ts";
import face_punch_event_client_hoiSl6wA4B from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/plugins/face-punch-event.client.ts";
import sw_client_RnWfLpTwX3 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/plugins/sw.client.ts";
import ws_client_dNongXUv79 from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/plugins/ws.client.ts";
export default [
  revive_payload_client_KzxDhi1XDX,
  unhead_gyRiG7iGz5,
  router_nnqqRzhIgQ,
  _0_siteConfig_8closNmbjF,
  _00_device_aUuIKrR2pg,
  check_outdated_build_client_teesXOJzgp,
  plugin_vue3_k5mbNhx8ZX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OJG4FunfXm,
  plugin_client_C1bowSuKi3,
  plugin_z79dVQDFd5,
  i18n_hujAepMKOT,
  siteConfig_Zep0zRmCO0,
  inferSeoMetaPlugin_HeXAwT4uNp,
  defaultsWaitI18n_J8rSNAGj0J,
  pwa_icons_TVzF1TY8bp,
  pwa_client_2KEKlcZTpL,
  chunk_reload_client_oqqOpC6h04,
  plugin_sC5UVbOOy7,
  varlet_e6cbQlfUS9,
  _00_chn_client_XVTQndAaeq,
  _00_error_3sMOwMwCju,
  _00_i18n_Wc20tMlzRa,
  _00_router_client_Rzbf5Pkz84,
  _02_track_client_U0FmS0gvV8,
  animate_6NGGNLtrdr,
  gtm_client_BwQMnIPzIp,
  rem_client_SiWW72QDVo,
  storage_client_8KnqLy3umo,
  timer_N4XvhmDp4S,
  volce_client_Oe0Cz9whbZ,
  _00_fingerprint_client_FeTQqCRBfI,
  _00_initialize_client_fjFfTWWSbo,
  _02_dialog_client_eTTcJMjZVV,
  _03_firebase_client_4CUfs1Y4Uo,
  auth_client_8Tb6Ckgkt3,
  face_punch_event_client_hoiSl6wA4B,
  sw_client_RnWfLpTwX3,
  ws_client_dNongXUv79
]